<template>
  <div class="container">
    <div class="blur" @click="close"></div>
    <!-- <transition name="fade"> -->
    <div class="subscribe">
      <h1 class="header">Unsubscribe</h1>
      <div class="info">Remove all update mails from us.</div>

      <div class="form">
        <div class="e-mail">
          <input
            type="email"
            name=""
            id="email"
            v-model="email"
            placeholder="email@domain.com"
          />
          <Icon v-if="validemail" style="color: green" class="valid">done</Icon>
          <!-- <Icon v-if="!validemail" style="color: red" class="valid"
            >priority_high</Icon
          > -->
        </div>
      </div>
      <div class="password">
        Password:<input
          type="password"
          name=""
          v-model="password"
          id="password"
        />
      </div>
      <div>
        <Button
          bgColor="var(--color-dark-green)"
          txtColor="#ffffff"
          shadow="var(--shadow-green)"
          @click="unsubscribe"
          >Unsubscribe</Button
        >
      </div>
    </div>
    <!-- </div> -->
    <!-- </transition> -->
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, computed, onMounted } from "vue"
import { useRoute } from "vue-router"
import Icon from "./../components/Icon.vue"
import Button from "./../components/Button.vue"
import axios from "axios"

const apiurl = "https://api.otonomi.no"
// const apiurl = "http://localhost:8085"

export default defineComponent({
  components: { Icon, Button },
  props: {},
  setup(props, { emit }) {
    const route = useRoute()

    const close = (event: any) => {
      emit("close")
    }
    const email = ref("")
    const password = ref("")
    onMounted(() => {
      if (route.query.email != undefined) {
        email.value = route.query.email.toString()
      }
    })

    const validemail = computed(() => {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(email.value).toLowerCase())
    })

    const unsubscribe = () => {
      if (validemail.value == true) {
        axios({
          baseURL: apiurl,
          url: "/unsubscribe",
          method: "post",
          data: {
            email: email.value,
          },
        })
          .then((res) => {
            return
          })
          .catch((err) => {
            console.log(err)
          })
        // emit("close")
      }
    }

    return {
      close,
      email,
      password,
      unsubscribe,
      validemail,
    }
  },
})
</script>
<style lang="scss" scoped>
.blur {
  position: fixed;
  backdrop-filter: blur(6px);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .subscribe {
    width: 600px;
    padding: 40px 50px;
    background-color: white;
    border-radius: 12px;
    box-shadow: var(--shadow-dark);
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    @media (max-width: 800px) {
      width: 500px;
      padding: 40px 40px;
    }
    @media (max-width: 600px) {
      width: 400px;
      padding: 40px 20px;
    }

    h1 {
      margin-bottom: 1.2rem;
    }

    .info {
      margin: 0 auto 1.5rem;
    }

    .close {
      position: absolute;
      right: 30px;
      top: 30px;
      font-size: 2rem;
      cursor: pointer;
    }

    .form {
      margin-bottom: 2rem;
    }
  }
}
.e-mail {
  // background-color: lightgray;
  position: relative;
  width: 60%;

  margin: 10px auto;
}
.valid {
  position: absolute;
  top: 15px;
  right: -20px;
}
.button {
  padding: 5px 5px;
}
input {
  background-color: #eee;
  border: none;
  padding: 12px 15px;
  margin: 8px 0;
  width: 100%;
}
.password {
  height: 0px;
  opacity: 0;
  display: none;
}
</style>
