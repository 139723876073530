
import { defineComponent, ref, computed, onMounted } from "vue"
import { useRoute } from "vue-router"
import Icon from "./../components/Icon.vue"
import Button from "./../components/Button.vue"
import axios from "axios"

const apiurl = "https://api.otonomi.no"
// const apiurl = "http://localhost:8085"

export default defineComponent({
  components: { Icon, Button },
  props: {},
  setup(props, { emit }) {
    const route = useRoute()

    const close = (event: any) => {
      emit("close")
    }
    const email = ref("")
    const password = ref("")
    onMounted(() => {
      if (route.query.email != undefined) {
        email.value = route.query.email.toString()
      }
    })

    const validemail = computed(() => {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(email.value).toLowerCase())
    })

    const unsubscribe = () => {
      if (validemail.value == true) {
        axios({
          baseURL: apiurl,
          url: "/unsubscribe",
          method: "post",
          data: {
            email: email.value,
          },
        })
          .then((res) => {
            return
          })
          .catch((err) => {
            console.log(err)
          })
        // emit("close")
      }
    }

    return {
      close,
      email,
      password,
      unsubscribe,
      validemail,
    }
  },
})
